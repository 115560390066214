import store from '../../store'
import http from './http'
import lo from 'lodash'
import fixUrlSuffix from '../../shared/utils/fixUrlSuffix'
import { API_KEY } from '../../fixtures/apiUrls'
import axios from 'axios'
import { CANCEL } from 'redux-saga'

export const request = (config, isProtected) => {

  const { auth } = store.getState()
  
  // 4 Oct 2021. We now use API Key
  config.headers = { 
    ...config.headers, 
    "X-API-Key": API_KEY,
    "X-Service-Key": auth.userInfo.business_uid
  }
  
  if (isProtected)
    config.headers = { ...config.headers, Authorization: `jwt ${auth.token}` }

  // Make sure url has suffix "/" as required by our backend
  let url = fixUrlSuffix(config.url)
  if (config.urlParams) {
    const urlStr = Object.keys(config.urlParams)
      .filter(k => !(config.urlParams[k] === null || config.urlParams[k] === undefined))
      .map(k => (
        encodeURIComponent(k) + "=" + encodeURIComponent(config.urlParams[k])
      )).join("&")
    if (urlStr) {
      if (url.indexOf("?") >= 0)
        url += "&" + urlStr
      else
        url += "?" + urlStr
    }
  }

  config = { ...config, url }

  return http.request(lo.pick(config, ['url', 'method', 'data', 'headers', 'cancelToken', 'timeout', 'onUploadProgress']))
}

export const get = async (url, opts = {}, isProtected = true) => {
  const options = {
    method: 'get',
    url,
    // urlParams: opts.urlParams,
    // headers: opts.headers,
    ...opts
  }

  return request(options, isProtected)
}

export const post = async (url, body, opts = {}, isProtected = true) => {
  const source = axios.CancelToken.source()
  const options = {
    method: 'post',
    url,
    // urlParams: opts.urlParams,
    data: body,
    // headers: opts.headers,
    cancelToken: source.token,
    ...opts
  }

  request[CANCEL] = () => source.cancel()

  return request(options, isProtected)
}

export const patch = async (url, body, opts = {}, isProtected = true) => {
  const options = {
    method: 'patch',
    url,
    // urlParams: opts.urlParams,
    data: body,
    // headers: opts.headers,
    ...opts
  }

  return request(options, isProtected)
}

export const put = async (url, body, opts = {}, isProtected = true) => {
  const options = {
    method: 'put',
    url,
    // urlParams: opts.urlParams,
    data: body,
    // headers: opts.headers,
    ...opts,
  }

  return request(options, isProtected)
}