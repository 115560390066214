import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import { useMediaQuery, useTheme, Box as MuiBox } from '@material-ui/core'
import { InputField, Button } from './'

import {
  Search as SearchIcon,
  FilterList as FilterIcon,
} from '@material-ui/icons'
import useStyles from './styles'

export const FilterBar = (props) => {
  const { primaryFields, secondaryFields } = props
  const { filterData, onFilterDataChange } = props
  const { primaryButtons, searchRef } = props
  const classes = useStyles()
  const theme = useTheme()

  const onFieldChange = (fieldName) => (value) => {
    onFilterDataChange(fieldName, value)
  }

  const getFieldValue = (fieldName) => {
    return filterData && filterData[fieldName]
  }

  const createInputField = (field, barVariant) => {
    let defaultFieldProps = {
      showLabel: false,
      // hideOptionArrow: true,
      clearButton: "auto",
      tintSelected: true
    }

    if (field.kind === "search")
      defaultFieldProps = {
        ...defaultFieldProps,
        icon: SearchIcon,
        inputRef: searchRef
      }
    if (field.kind === "filter")
      defaultFieldProps = {
        ...defaultFieldProps,
        icon: FilterIcon,
      }
    if (barVariant === "primary")
      defaultFieldProps = {
        ...defaultFieldProps,
        variant: "filled",
        fullWidth: true,
      }
    else if (barVariant === "secondary")
      defaultFieldProps = {
        ...defaultFieldProps,
        size: "small",
        // variant: "clean",
      }

    return (
      <div className={clsx(
        barVariant === "primary" && classes.filterBarPrimaryField,
        barVariant === "secondary" && classes.filterBarSecondaryField,
        field.kind === "search" && classes.filterBarSearchField,
        barVariant === "secondary" && field?.type !== "option" && classes.filterBarAddedHeight,
        barVariant === "secondary" && field?.type === "date-picker" && classes.filterBarWidthLimit
      )}
      >
        <InputField {...defaultFieldProps} {...field}
          onChange={onFieldChange(field.name)}
          value={getFieldValue(field.name)}
        />
      </div>
    )
  }

  return (
    <div className={classes.filterBar}>
      {primaryFields &&
        <div className={classes.filterBarPrimaryRow}>
          {primaryFields
            .map(field => createInputField(field, "primary"))
            // .reduce((p, n) => p ?
            //   [...p, <div className={classes.filterBarPrimaryFieldSeparator} />, n] : [n]
            //   , null)
          }
          {primaryButtons && primaryButtons.map((b, i) =>
            <Button color="primary" {...b} key={i} />
          ).reduce((p, n) => p ?
            [...p, <MuiBox ml={1} />, n] : [n]
            , null)
          }
        </div>
      }
      {secondaryFields &&
        <div className={classes.filterBarSecondaryRow}>
          {secondaryFields
            .map(field => createInputField(field, "secondary"))
          }
        </div>
      }
    </div>
  )
}

export default FilterBar