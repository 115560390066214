import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    //backgroundColor: '#ececec'
  },
  backgroundImageOverlay: {
    zIndex: -1,
    position: 'fixed',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },  
  // Navigation Bar
  navigationBar: {    
    position: 'fixed',
    top: 0,
    zIndex: 1100, // App Bar
    width: '100%',
    height: 32,
//    borderBottom: 'solid 1px #ccc',
//    borderBottom: 'solid 1px rgb(0, 0, 0, .1)', // #aaa', // Dark
    display: 'flex',
    justifyItems: 'center',
    alignItems: 'center',
    // paddingLeft: theme.spacing(1),
    // paddingRight: theme.spacing(1),    
  },
  navigationBarBackground: {
    position: 'fixed',
    zIndex: 1100, // App Bar    
    top: 0,
    height: 32,
    minHeight: 32,
    width: '100%',
    overflow: "hidden",
    // Without this, we can't tap on content area. Probably because of background div
    pointerEvents: "none",
  },
  navigationBarHeight: {
    height: 32,
  },
  navigationBarLeftPanel: {
    paddingRight: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexGrow: 1,    
    },
    
  },
  navigationBarCenterPanel: {
    flexGrow: 1,
    align: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  navigationBarNotificationPanel: {
    paddingLeft: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    height: '100%'
  },
  navigationBarTitle: {
    fontWeight: 800,
    fontSize: theme.typography.body1.fontSize,
    color: '#fff'
  },
  navigationBarButtonWrapper: {
    // Required for selected button indicator to work
    position: 'relative',
  },
  navigationBarButton: {    
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,    
    whiteSpace: 'nowrap',
    color: 'rgb(255, 255, 255, .8)',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  navigationBarSelectedButton: {
    fontWeight: 800,
    color: '#fff'    
  },
  navigationBarSelectedButtonIndicator: {
    height: '3px',
    position: 'absolute',
    backgroundColor: theme.palette.primary.main,
    backgroundColor: 'rgb(255, 255, 255, .6)',
    width: '100%',
    bottom: 0
  },
  navigationBarButtonSeparator: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: '1px',
    height: '100%', 
    backgroundColor: '#ccc'
  },
  notificationItemButton: {    
    textTransform: 'none',
    fontSize: theme.typography.body2.fontSize,
    fontWeight: 400,    
    whiteSpace: 'nowrap',    
    color: 'rgb(255, 255, 255, .8)',
  },  
  notificationItemIcon: {
    color: '#fff',
    fontSize: '1.3rem',
  },
  menuItemIcon: {
    color: '#fff',
    fontSize: '1.3rem',
  },
  bottomNavBar: {    
    position: 'fixed',
    bottom: 0,
    zIndex: 1100, // App Bar
    width: '100%',
    height: 56,
    borderTop: 'solid 1px #ddd',
    backgroundColor: 'white',
  },
  bottomNavBarHeight: {
    height: 56 + 16,
  },
  bottomNavBarActionItem: {
    minWidth: 60,
    paddingLeft: 6,
    paddingRight: 6,
  },
  badgeShape: {
    width: theme.spacing(1),
    height: theme.spacing(1),
    borderRadius: theme.spacing(2)
  },
  userBadgeAvailable: {
    backgroundColor: 'green',
    border: 'solid 1px white'
  },
  userBadgeNotAvailable: {
    backgroundColor: '#cd853f',
  },    
  userBadgeOffline: {
    backgroundColor: '#aaa',
  },
  linearProgress: {
    position: 'fixed',
    height: 2,
    overflow: 'hidden',
    width: '100%',
    zIndex: 1250 // Above Drawer (1200)
  },
  title: {
    flexGrow: 1,
    fontWeight: 800,
    paddingLeft: theme.spacing.unit * 2,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
  },
  submenu: {
    zIndex: '1305 !important',
  },
  containerSelectThemes: {
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'absolute',
    padding: '10px',
    bottom: 0,
  },
  buttonSelectTheme:{
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    fontWeight: 700,
    "&:hover": {
      backgroundColor: '#e0e0e0'
    }
  },
  contentPopoverTheme:{
    padding: '10px 16px',
    position: 'absolute',
    justifyContent: 'flex-end',
    backgroundColor: '#fff',
    display: 'inline-flex',
    flexDirection: 'column',
    borderRadius: '10px',
    left: '45px',
    bottom: '55px',
    gap: '11px',
    cursor: 'pointer'
  }
}), {index: 1})
// Don't forget to add index:1, otherwise makeStyles classes will be injected
// after material-ui base styles. Strangely, it happens only on production build.
// On development build, I don't need to add this.
// Reference: https://stackoverflow.com/questions/62473898

export default useStyles;
