import {
  Tab as MuiTab,
  makeStyles,
} from '@material-ui/core'
import useStyles from './styles'

export const Tab = ({label, count, ...props}) => {
  const classes = useStyles()

  const newLabel = count ? `${label} (${count})` : label

  return (
    <MuiTab
      wrapped
      classes={{
        root: classes.tab,
        selected: classes.tabSelected,
      }}
      label={newLabel}
      {...props}
      >
    </MuiTab>
  )
}

export default Tab