import {
  Home as HomeIcon,
  People as ContactsIcon,
  Inbox as InboxIcon,
  Assignment as ServiceIcon,
  Storefront as CatalogIcon,
  Receipt as OrderIcon
} from '@material-ui/icons'


export const appList = [
  {
    name: "Home",
    appRoot: "./apps/home",
    path: "home",
    icon: HomeIcon,
  },  
  {
    name: "Contacts",
    appRoot: "./apps/contacts",
    path: "contacts",
    icon: ContactsIcon,
    required_permissions: ["contact.browse_contact"],
  },
  {
    name: "Inbox",
    appRoot: "./apps/conversations",
    path: "conversations",
    icon: InboxIcon,
    required_permissions: ["conversation.browse_conversation"],
  },
  // {
  //   name: "Workflow",
  //   appRoot: "./apps/dummy",
  //   path: "task",
  // },    
  {
    name: "Sales",
    appRoot: "./apps/sales",
    path: "sales",
    hideAppIcon: true, // Not yet ready
  },
  {
    name: "Outbound",
    appRoot: "./apps/marketing",
    path: "marketing",
    hideAppIcon: false, // For experimental only, not production
  },
  {
    name: "Contents",
    appRoot: "./apps/dummy",
    path: "content",
    hideAppIcon: true, // For experimental only, not production
  },
  {
    name: "Projects",
    appRoot: "./apps/service",
    path: "service",
    icon: ServiceIcon,
    required_permissions: ["project.browse_issue"],
    action_key: 'projectList'
  },
  {
    name: "Store",
    appRoot: "./apps/catalog",
    path: "catalog",
    icon: CatalogIcon,
    hideAppIcon: true, // hidden so it's not accessible from navbar
  },
  {
    name: "Orders",
    appRoot: "./apps/orders",
    path: "orders",
    icon: OrderIcon,
    hideAppIcon: false,
    required_permissions: ["sales.browse_order"],
  },
  {
    name: "Commerce",
    appRoot: "./apps/commerce",
    path: "commerce",
    hideAppIcon: true, // For experimental only, not production
  },
  {
    name: "Settings",
    appRoot: "./apps/settings",
    path: "settings",
    hideAppIcon: true,
  },    
]
