import React, { useState, useEffect, useContext } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useLocation, useHistory } from "react-router-dom"
import { Link } from 'react-router-dom'
import useStyles from './styles'
import clsx from 'clsx'
import SideNavigationView from './SideNavigationView'
import {
  MenuPopover,
} from 'shared/ui'
import {
  Button,
  Typography,
  Box,
  Badge,
} from '@material-ui/core'

import {
  Home as HomeIcon,
  ExpandMore
} from '@material-ui/icons'

const NavigationBar = ({ variant, title, menuItems, notificationItems }) => {

  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
  const [popoverMenuItems, setPopoverMenuItems] = useState(null)
  const classes = useStyles()
  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))
  const [openSideMenu, setOpenSideMenu] = useState(false)
  const history = useHistory()

  const showPopoverMenu = (target, menuItems) => {
    setPopoverMenuItems(menuItems)
    setPopoverAnchorEl(target)
  }

  const hidePopoverMenu = () => {
    setPopoverMenuItems(null)
    setPopoverAnchorEl(null);
  }

  let rootStyle = {}
  /*
  if (variant == 'transparent')
    rootStyle['backgroundColor'] = 'inherit'
  else if (backgroundColor)
    rootStyle['backgroundColor'] = backgroundColor
  */

  return (
    <div className={classes.navigationBar}
      style={rootStyle}
    >
      {smDown ?
        <div className={classes.navigationBarLeftPanel}>
          {/*
          <Button onClick={() => setOpenSideMenu(!openSideMenu)}>
            <HomeIcon />
          </Button>
          <SideNavigationView
            open={openSideMenu}
            onClose={() => setOpenSideMenu(false)}
            title={title}
            menuItems={menuItems}
          />
          */}
        </div>
        :
        <>
          <div className={classes.navigationBarLeftPanel}>
            <Box ml={1} mr={3}>
              <Typography color='' className={classes.navigationBarTitle}>
                {title}
              </Typography>
            </Box>

            {menuItems.map(item =>
              <Box className={classes.navigationBarButtonWrapper}>
                {item.selected &&
                  <div className={classes.navigationBarSelectedButtonIndicator} />
                }

                <Button color='inherit'
                  className={clsx(classes.navigationBarButton, item.selected && classes.navigationBarSelectedButton)}
                  // component={Link}
                  // to={item.linkTo}
                  onClick={(event) => {
                    if (item.actionItems?.length > 0) {
                      showPopoverMenu(event.target, item.actionItems)
                    }
                    else if (item.linkTo)
                      history.push(item.linkTo)
                    else if (item.onItemClick)
                      item?.onItemClick()
                  }}
                >
                  {item.title || item.label}
                  {item.actionItems.length > 0 && (
                    <ExpandMore />
                  )}
                </Button>
              </Box>
            )}
          </div>
          <div className={classes.navigationBarCenterPanel}>
          </div>
        </>
      }

      <div className={classes.navigationBarNotificationPanel}>
        {notificationItems.map(item =>
          <Box>
            <Button
              className={classes.notificationItemButton}
              onClick={(event) => {
                if (item.actionItems) {
                  showPopoverMenu(event.target, item.actionItems)
                }
                else if (item.linkTo)
                  history.push(item.linkTo)
                else if (item.onItemClick)
                  item?.onItemClick()
              }}
            >
              <Badge variant={item.badgeVariant || "standard"}
                classes={{ badge: item.badgeClassName }}
                overlap={item.badgeOverlap || "rectangle"}
              >
                {React.createElement(item.icon, {
                  className: classes.notificationItemIcon,
                })
                }
              </Badge>
              {item.label &&
                <Box ml={1}>
                  {item.label}
                </Box>
              }
            </Button>
          </Box>
        )}
        {popoverMenuItems && popoverAnchorEl &&
          <MenuPopover
            menuItems={popoverMenuItems}
            open={true}
            anchorEl={popoverAnchorEl}
            onClose={hidePopoverMenu}
          />
        }
      </div>
    </div>
  )
}

export default NavigationBar