import React, { useState, useEffect, useContext } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import useStyles from './styles'
import BoardColumn from './BoardColumn'
import { DragDropContext } from "react-beautiful-dnd"

export const Board = (props) => {
  const { columns, items, cardFields, skeleton, isFetching, onMoveItem, onCardClick, onItemSelect, isItemSelected } = props
  
  const classes = useStyles()
  const theme = useTheme()

  function getColumnItems(col) {
    if (col.getItems)
      return col.getItems(items)
    return items.filter(item => item.key === col.key)
  }

  const onDragTaskStart = (item) => {
  }

  const onDragTaskEnd = (result) => {
    // alert("onDragTaskEnd: " + JSON.stringify(result, null, 2))

    if (!onMoveItem)
      return

    if (!result.destination || (
        result.source.droppableId === result.destination.droppableId &&
        result.source.index === result.destination.index
        ))
      return

    const sourceCol = columns.find(col => col.droppableId === result.source.droppableId)
    const destCol = columns.find(col => col.droppableId === result.destination.droppableId)    

    const item = sourceCol.getItems(items)[result.source.index]        

    onMoveItem(item, sourceCol, result.source.index, destCol, result.destination.index)
  }
  
  return (
    <DragDropContext onDragStart={onDragTaskStart} onDragEnd={onDragTaskEnd}>          
      <div className={clsx(classes.board)}>
        {columns
          .map(col =>
              <BoardColumn 
                {...col} 
                items={getColumnItems(col)}
                cardFields={cardFields}
                />
            )
          .reduce((p, n) => p ? [...p, <div className={classes.boardColumnDivider}/>, n] : [n]
            , null)
        }
      </div>
    </DragDropContext>
  )
}

export default Board