import React, { useState, useEffect } from 'react'
import { withResizeDetector } from 'react-resize-detector'
import { FileInput, Button, Text } from '.'
import { Box, LinearProgress, useTheme } from "@material-ui/core"
import { Description } from "@material-ui/icons"
import useStyles from './styles'

function FileListInputInner(props) {
  const {
    value,
    width,
    height,
    onChange,
    onUploadImage,
    onCancelUpload = null,
    name = 'attachments',
    required = false,
    style = {}
  } = props
  const [isUploading, setIsUploading] = useState(false)
  const [uploadPercentage, setUploadPercentage] = useState(0)
  const classes = useStyles()
  const theme = useTheme()

  const upload = (file) => {
    setIsUploading(true)
    onUploadImage(
      file,
      name,
      () => setIsUploading(false),
      (e) => {
        setUploadPercentage(Math.round((100 * e?.loaded) / e?.total))
      },
      () => setUploadPercentage(0)
    )
  }

  return (
    <div className={classes.imageListInput} style={style}>
      <div
        className={classes.imageListInputContent}
        style={{
          // position: "absolute",
          width: `${width}px`,
          height: `${height}px`,
          overflowX: "auto",
          overflowY: "hidden",
        }}
      >
        {value && value.map((image, index) =>
          <FileInput value={image}
            allowDelete={true}
            onDelete={() => {
              let list = [...value]
              list.splice(index, 1)
              onChange(list)
            }}
          />
        ).reduce((p, n) => p ?
          [...p, <div className={classes.imageListInputSpacing} />, n] : [n]
          , null
        )}
        {value &&
          <div className={classes.imageListInputSpacing} />
        }
        {isUploading &&
          <>
            <div className={classes.imageInput}>
              <div className={classes.imageInputPlaceholder}>
                {onCancelUpload &&
                  <div className={classes.cancelUpload}>
                    <Button
                      kind="cancel"
                      variant="icon"
                      size="small"
                      onClick={() => onCancelUpload(() => setIsUploading(false))}
                    />
                  </div>
                }
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Description style={{ marginBottom: theme.spacing(1) }} />
                  <Text variant="caption" color='textMuted'>
                    {`${uploadPercentage}%`}
                  </Text>
                  <div className={classes.uploadProgress}>
                    <LinearProgress />
                  </div>
                </Box>
              </div>
            </div>
            <div className={classes.imageListInputSpacing} />
          </>
        }
        <FileInput onUploadImage={upload} />
        {required &&
          <span style={{ marginLeft: '0.5rem', color: 'red' }}>*</span>
        }
      </div>
    </div>
  )
}

export const FileListInput = withResizeDetector(FileListInputInner)

export default FileListInput