import React, { useState, useEffect, useContext } from 'react'
import { useLocation, useHistory } from "react-router-dom"
import { Link } from 'react-router-dom'
import useStyles from './styles'
import clsx from 'clsx'
import {
  BottomNavigation,
  BottomNavigationAction,
} from '@material-ui/core'

import {
  Apps as AppsIcon,
} from '@material-ui/icons'

const BottomNavBar = ({ menuItems }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.bottomNavBar}>
      <BottomNavigation 
        showLabels
        value={menuItems.findIndex(m => m.selected)}
        >
        {menuItems.map(item =>
          <BottomNavigationAction 
            label={item.title}
            icon={React.createElement(item.icon)}
            onClick={() => history.push(item.linkTo)}
            classes={{
              root: classes.bottomNavBarActionItem
            }}
            />
        )}
      </BottomNavigation>
    </div>
  )
}

export default BottomNavBar