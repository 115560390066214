import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import { useMediaQuery, useTheme } from '@material-ui/core'
import {
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Box
} from '@material-ui/core'
import {
  ExpandMore as ExpandIcon
} from '@material-ui/icons'
import {
  List,
  Button,
} from '.'
import useStyles from './styles'

export const Panel = (props) => {
  const { title, titleButtons, defaultExpanded, variant, gutterBottom, collapsible = false, isFlushed = false, classPanelTitleText = '' } = props
  const classes = useStyles()
  const theme = useTheme()

  const content = React.Children.map(props.children, e => {
    // Add custom wrapper to enable full bleed inside panel
    // to some known element. We should do this in CSS using 
    // nested selected, but don't know how to do this in material-ui yet
    if (React.isValidElement(e)) {
      if (e.type == List)
        return (<div className={classes.panelList}>{e}</div>)
    }

    return e
  })

  return (
    <div className={clsx(
      classes.panel,
      !isFlushed && classes.panelShadow,
      isFlushed && classes.panelBorder,
      gutterBottom && classes.panelGutterBottom,
    )}>
      <ExpansionPanel
        defaultExpanded={defaultExpanded}
        elevation={0}
        square={1}
      >
        <ExpansionPanelSummary
          expandIcon={<ExpandIcon />}
          // classes={{ root: isFlushed && classes.panelFlushed }}
        >
          <Box display="flex" width="100%" alignItems="center">
            <Box flexGrow={1} mr={1}>
              <Typography className={clsx(classes.panelTitleText, classPanelTitleText)}>{title}</Typography>
            </Box>
            {titleButtons && titleButtons.map(button => (
              <Button {...button}
                onClick={(event) => { event.stopPropagation(); button.onClick(); }}
              />
            ))}
          </Box>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails
          // classes={{ root: isFlushed && classes.panelDetailFlushed }}
          className={classes.panelExpansionContent}
        >
          {content}
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>
  )
}

export default Panel