import React, { useState, useEffect, useContext } from 'react'
import { useMediaQuery, useTheme } from '@material-ui/core'
import clsx from 'clsx'
import Skeleton from '@material-ui/lab/Skeleton'
import {
  CircularProgress,
} from '@material-ui/core'
import {
  Text
} from '.'
import useStyles from './styles'
import CardList from './CardList'

export const BoardColumn = (props) => {
  const { name, count, cardFields, ...listProps } = props
  
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={clsx(classes.boardColumn)}>
      <div className={classes.boardColumnHeader}>
        {name}
      </div>
      <CardList 
        variant="card"
        {...listProps}
        fields={cardFields}
        />
    </div>
  )
}

export default BoardColumn