import ReactMarkdown from 'react-markdown'
import {
  useTheme,
} from '@material-ui/core'
import { Text } from '.'
import useStyles from './styles'
import { useEffect, useState } from 'react'

export const RichText = ({ source, format = "markdown", size }) => {
  const [text, setText] = useState('')

  const classes = useStyles()
  const theme = useTheme()
  let customStyles = {
    wordBreak: "break-word",
  }

  const renderers = {
    paragraph: ({ children, index, parentChildCount }) => (
      <Text
        gutterBottom={index === parentChildCount - 1 ? false : true}
        variant={size === "small" ? "body2" : "body1"}
      >
        {children}
      </Text>
    ),
    image: ({ alt, src, title, }) => (
      <img
        alt={alt}
        src={src}
        title={title}
        style={{ maxWidth: 200 }}
      />
    ),
  }

  const escapeHtml = (unsafe) => {
    return unsafe
      .replace(/&/g, "&amp;")
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;")
      .replace(/"/g, "&quot;")
      .replace(/'/g, "&#039;")
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
  }

  const handleFormat = () => {
    const htmlFormat = [
      { symbol: '*', tag: 'b' },
      { symbol: '_', tag: 'em' },
      { symbol: '~', tag: 'del' },
      { symbol: '```', tag: 'code' },
      { symbol: '@', tag: 'a' },
    ]

    const sanitize = escapeHtml(source)
    let converter = sanitize;

    htmlFormat.forEach(({ symbol, tag }) => {
      // This is not supported in Safari
      // https://stackoverflow.com/questions/51568821
      // const regex = new RegExp(`(?<=\\${symbol})(.*?)(?=\\${symbol})`, 'gm')
      const regex = new RegExp(`\\${symbol}([^${symbol}]*)\\${symbol}`, 'gm')

      const match = source.match(regex);
      if (match) {
        match.forEach(m => {
          let formatted = m;
          for (let i = 0; i < 2; i += 1) {
            formatted = formatted.replace(symbol, `<${i > 0 ? '/' : ''}${tag}>`);
          }
          converter = converter.replace(m, formatted)
        });

        // match.forEach(m => {
        //   if (m){
        //     let formatted = m;
        //     formatted = `<${tag}>${m}</${tag}>`
        //     converter = converter.replace(`${symbol}${m}${symbol}`, formatted)
        //   }
        // })
      }
    })

    const regexUrl = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig
    converter = converter.replace(regexUrl, function(url) {
      return '<a target="_blank" rel="noreferrer" style="text-decoration: none" href="' + url + '">' + url + '</a>';
    })

    setText(converter)
  }

  useEffect(() => {
    if (source) handleFormat()
  }, [source])

  return format === 'markdown' ? (
    <div className={classes.richTextStyle}>
      <ReactMarkdown
        source={source}
        renderers={renderers}
        includeNodeIndex={true}
      />
    </div>
  ) : (
    <Text variant={size === "small" ? "body2" : "body1"}>
      <div
        className={classes.richTextStyle}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    </Text>
  )
}

export default RichText