import React from 'react'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import {
  Text,
  Box,
} from '.'
import {
  ListItem as MuiListItem,
  ListItemText as MuiListItemText,
  ListItemIcon as MuiListItemIcon,
  useTheme,
} from '@material-ui/core'
import useStyles from './styles'
import { Draggable } from "react-beautiful-dnd"

const MyLink = (props) => {
  // This will create black text on list item, but it will stay black
  // when selected. So I'll use this component only when not selected.
  return <Link {...props} style={{ color: 'inherit', textDecoration: 'none' }} />

  // Don't know why this crash. This should be the best solution.
  const classes = useStyles()
  return
  <Link className={classes.link} {...props} />
}

export const ListItem = ({
  icon, primary, secondary, label, linkTo, selected, onClick, noGutter = false, iconSize = 'small', labelTypographyProps = {},
  variant = 'button', draggableId, index, rightIcon, ...props
}) => {
  const primaryTypographyProps = { variant: "body2", ...(props.primaryTypographyProps || {})}
  const secondaryTypographyProps = props.secondaryTypographyProps || {}

  const classes = useStyles()
  const theme = useTheme()
  let iconStyle = {}
  if (selected)
    iconStyle["color"] = theme.palette.primary.main
  else
    iconStyle["color"] = "#444"

  let listItem

  if (variant == 'link')
    listItem = (
      <MuiListItem
        className={clsx(classes.listItem, classes.listItemLink, selected && classes.listItemLinkSelected, onClick && classes.pointer)}
        component={selected ? Link : MyLink}
        to={linkTo}
        onClick={onClick}
      >
        {icon &&
          <Box className={classes.listItemIcon}>
            {React.createElement(icon, { fontSize: iconSize, style: iconStyle })}
          </Box>
        }
        <Box className={classes.listItemText}>
          <Text variant="body1">{primary}</Text>
          {secondary &&
            <Text variant="body2">{secondary}</Text>
          }
        </Box>
      </MuiListItem>
    )
  else if (variant == 'menuitem')
    listItem = (
      <MuiListItem
        className={clsx(classes.listItem, classes.listItemMenu, selected && classes.listItemLinkSelected, onClick && classes.pointer)}
        component={selected ? Link : MyLink}
        to={linkTo}
        onClick={onClick}
      >
        {icon &&
          <div className={classes.listItemIcon}>
            {React.createElement(icon, { fontSize: iconSize, style: iconStyle })}
          </div>
        }
        <div className={classes.listItemMenuPrimary}>
          <Text variant="body2">{primary}</Text>
        </div>
        {secondary &&
          <div className={classes.listItemMenuSecondary}>
            <Text variant="body2" color="textMuted">{secondary}</Text>
          </div>
        }
      </MuiListItem>
    )
  else if (variant === "card")
    listItem = (
      <MuiListItem
        className={clsx(classes.listItem, classes.listItemButton, selected && classes.listItemButtonSelected, onClick && classes.pointer)}
        component={selected ? Link : MyLink}
        to={linkTo}
        onClick={onClick}
        classes={{ root: classes.card }}
      >
        {icon &&
          <Box className={classes.listItemIcon}>
            {React.createElement(icon, { fontSize: iconSize, style: iconStyle })}
          </Box>
        }
        <MuiListItemText primary={primary} secondary={secondary} primaryTypographyProps={primaryTypographyProps} />
        {rightIcon && <MuiListItemIcon>{rightIcon}</MuiListItemIcon>}
      </MuiListItem>
    )
  else
    listItem = (
      <MuiListItem
        className={clsx(classes.listItem, classes.listItemButton, selected && classes.listItemButtonSelected, noGutter && classes.listItemNoMargin, onClick && classes.pointer)}
        component={selected ? Link : MyLink}
        to={linkTo}
        onClick={onClick}
        {...props}
      >
        {icon &&
          <Box className={classes.listItemIcon}>
            {React.createElement(icon, { fontSize: iconSize, style: iconStyle })}
          </Box>
        }
        <Box width="100%" display="flex" flexDirection="column" alignItems="stretch">
          {label &&
            <Text variant="body2" color="textMuted" {...labelTypographyProps}>{label}</Text>
          }
          <MuiListItemText
            primary={primary}
            secondary={secondary}
            primaryTypographyProps={primaryTypographyProps}
            secondaryTypographyProps
          />
        </Box>
        {rightIcon &&
          <MuiListItemIcon classes={{ root: classes.noMinWidth }}>
            {rightIcon}
          </MuiListItemIcon>
        }
      </MuiListItem>
    )

  if (draggableId) {
    return (
      <Draggable draggableId={draggableId} index={index}>
        {provided => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            tabIndex={null}
          >
            {listItem}
          </div>
        )}
      </Draggable>
    )
  }
  else
    return listItem
}

export default ListItem