import React, { useState, useEffect, useContext, useCallback } from "react"
import clsx from "clsx"
import formatValue from "../utils/formatValue"
import Skeleton from '@material-ui/lab/Skeleton'
import { Box, CircularProgress, useTheme, Checkbox } from "@material-ui/core"
import {
  List,
  Card,
  CardTitle,
  CardSubtitle,
  CardBody,
  CardMedia,
} from "."
import useStyles from './styles'

export const CardList = (props) => {
  const classes = useStyles()
  const theme = useTheme()
  const { fields, skeleton = 0, renderCardItem, itemListRef, items, selectedIndex, onCardClick, variant, isFetching, ...listProps } = props
  // destructure selection props
  const { showCheckBox, onRowSelect, isRowSelected } = props

  const onCardItemClick = useCallback((item) => () => onCardClick && onCardClick(item),
    [onCardClick])

  const getFieldData = (item, field) => {
    if (!field)
      return null
    if (field.render)
      return field.render(item, field)

    return formatValue(item[field.key], field.format)
  }

  function renderCardSkeleton(index) {
    if (renderCardItem)
      return renderCardItem(undefined, index, { skeleton: 1 })
    else
      return (
        <Card variant={variant} skeleton={1}>
          {fields.title &&
            <CardTitle
              primary={<Skeleton />}
            />
          }
          {fields.subtitle &&
            <CardSubtitle
              primary={<Skeleton />}
            />
          }
          {fields.body &&
            <CardBody
              rows={fields.body.map(field => <Skeleton />)}
            />
          }
        </Card>
      )
  }

  function renderCard(item, index) {
    const draggableId = listProps.droppableId ? `${listProps.droppableId}-${index}` : undefined
    if (renderCardItem)
      return renderCardItem(item, index, {
        variant: variant,
        selected: index == selectedIndex,
        index: index,
        onClick: onCardItemClick(item),
        draggableId,
      })
    else
      return (
        <div className={classes.selectableCardWrapper}>
          {showCheckBox && (
            <div className={classes.listCheckbox}>
              <Checkbox
                checked={isRowSelected && isRowSelected(item, index)}
                onChange={(event) => onRowSelect && onRowSelect(item, index)}
              />
            </div>
          )}
          <Card
            variant={variant} onClick={onCardItemClick(item)}
            key={index}
            selected={index == selectedIndex}
            index={index}
            draggableId={draggableId}
          >
            {fields.media &&
              <CardMedia
                selected={index == selectedIndex}
                media={getFieldData(item, fields.media)}
                width={fields.media.width}
                aspectRatio={fields.media.aspectRatio}
              />
            }
            {fields.title &&
              <CardTitle
                selected={index == selectedIndex}
                primary={getFieldData(item, fields.title.primary)}
                secondary={getFieldData(item, fields.title.secondary)}
              />
            }
            {fields.subtitle &&
              <CardSubtitle
                selected={index == selectedIndex}
                primary={getFieldData(item, fields.subtitle.primary)}
                secondary={getFieldData(item, fields.subtitle.secondary)}
              />
            }
            {fields.body &&
              <CardBody
                selected={index == selectedIndex}
                rows={fields.body.map(field => getFieldData(item, field))}
              />
            }
          </Card>
        </div>
      )
  }

  return (
    <List variant={variant} {...listProps}>
      {skeleton ?
        [...Array(skeleton)].map((_, index) => renderCardSkeleton(index))
        : items
          .map((item, index) => (
            <div ref={ref => {if(itemListRef) itemListRef.current[index] = ref}}>
              {renderCard(item, index)}
            </div>
          ))
          .reduce((p, n) => p ?
            [...p, <div className={variant === "card" && classes.cardSeparator} />, n] :
            [n], null)
      }

      {isFetching &&
        <Box display='flex' justifyContent='center' style={{ padding: theme.spacing(2) }}>
          <CircularProgress size='1.5rem' disableShrink={true} />
        </Box>
      }
    </List>
  )
}

export default CardList