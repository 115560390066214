import ls from 'local-storage'
import { call, put, fork, select, takeLatest, takeEvery } from 'redux-saga/effects'
import * as types from '../types'
import * as api from '../../api'

function* getStoreList({}) {
  yield put({ type: types.STORE_SET_FETCHING_STORE_LIST })
  const { ok, data } = yield call(api.getStoreList)
  if (ok)
    yield put({ type: types.STORE_SET_STORE_LIST, data })    
  else
    yield put({ type: types.STORE_SET_FETCHING_STORE_LIST, isFetching: false })  
}

function* setAvailability({ key, availability }) {
  const { ok, data } = yield call(api.setAvailability, key, availability)
}

export function* storeSaga() {
  yield takeLatest(types.STORE_ACTION_GET_STORE_LIST, getStoreList)
}
