import React from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'
import {
  Button as MuiButton,
  IconButton as MuiIconButton,
  useTheme,
  Box as MuiBox,
  Badge as MuiBadge,
  CircularProgress as MuiCircularProgress,
} from '@material-ui/core'
import {
  AddIcon,
  RemoveIcon,
  BackIcon,
  MoreIcon,
  SortIcon,
  PrevIcon,
  NextIcon,
  ExpandIcon,  
  DropDownIcon,
  CloseIcon,
  ClearIcon,
  SendIcon,
  DeleteIcon,
  EditIcon,
  CancelIcon,
  SaveIcon,
  DoneIcon,
  ShareIcon,
  PersonIcon,
  CancelAltIcon,
  PaymentIcon,
  PrintIcon,
  LabelIcon,
  CartIcon,
  ArrowRightIcon,
  ATCIcon,
  ConversationIcon,
  PendingIcon,
  BlockIcon,
  MarkUnreadIcon,
  ImageLandscapeIcon,
  DuplicateIcon
} from 'shared/icons'
import useStyles from './styles'

export const IconByKind = {
  "add": AddIcon,
  "remove": RemoveIcon,
  "back": BackIcon,
  "prev": PrevIcon,
  "next": NextIcon,
  "more": MoreIcon,
  "sort": SortIcon,
  "expand": ExpandIcon,
  "close": CloseIcon,
  "drop-down": DropDownIcon,
  "clear": ClearIcon,
  "send": SendIcon,
  "delete": DeleteIcon,
  "edit": EditIcon,
  "cancel": CancelIcon,
  "save": SaveIcon,
  "done": DoneIcon,
  "share": ShareIcon,
  "person": PersonIcon,
  "cancel-alt": CancelAltIcon,
  "payment": PaymentIcon,
  "print": PrintIcon,
  "mark": LabelIcon,
  "cart": CartIcon,
  "arrow-right": ArrowRightIcon,
  "atc": ATCIcon,
  "chat": ConversationIcon,
  "snooze": PendingIcon,
  "mark-unread": MarkUnreadIcon,
  "block": BlockIcon,
  "image-landscape": ImageLandscapeIcon,
  "duplicate": DuplicateIcon
}

export const Button = (props) => {    
  const classes = useStyles()
  const {kind} = props
  const {
    label=props.title, 
    icon=(kind && IconByKind[kind]), 
    variant="text", 
    size, 
    insetRightMargin, insetLeftMargin, 
    linkTo, 
    badge,
    loading,
    children, ...buttonProps} = props
  const {showIcon=Boolean(icon), showLabel=Boolean(label)} = props
  
  let button
  if (variant === "link") {
    return (
      <Link to={linkTo} 
        className={classes.link} 
        onClick={props.onClick}>
        <span className={clsx(
            classes.buttonLink,
            props.size === "small" && classes.buttonSizeSmall,
          )}>
          {label}{props.children}
        </span>
      </Link>
    )
  }

  // if (false && icon && !label)
  if (variant === "icon")
    button =
      <MuiIconButton size={size} {...buttonProps}>
        {loading ?
          <MuiCircularProgress size={20} />
        : React.isValidElement(icon) ? icon : React.createElement(icon)
        }
      </MuiIconButton>
  else      
    button =
      <MuiButton 
        className={clsx(
          classes.button,
          variant=="text" && classes.buttonText,
          insetLeftMargin && classes.buttonInsetLeftMargin,
          insetRightMargin && classes.buttonInsetRightMargin,
          size == "small" && classes.buttonSmall,
          size == "xsmall" && classes.buttonXsmall,
          )} 
        variant={variant}
        classes={{
          sizeSmall: classes.buttonSizeSmall,
        }}
        size={size}
        {...buttonProps}
        >
        {loading ?
          <MuiCircularProgress size={20} />
        :
          <MuiBadge badgeContent={badge} color="primary">
          {showIcon ?
            <MuiBox display='flex' alignItems='center'>
              {icon && 
                <>
                {React.isValidElement(icon) ? icon : React.createElement(icon, { fontSize: size })}
                <MuiBox mr={0.5} />              
                </>
              }
              {showLabel && label}
              {children}
            </MuiBox>
          : 
            <>
              {label}
              {children}
            </>
          }
          </MuiBadge>
        }
      </MuiButton>
    
  if (linkTo)
    return <Link to={linkTo} className={classes.link}>{button}</Link>
  else
    return button  
}

export default Button