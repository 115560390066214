import {
  Dialog as MuiDialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  useTheme,
  useMediaQuery
} from '@material-ui/core'

import { Button } from '.'

import useStyles from './styles'

export const Dialog = ({
  title,
  actionButtons,
  children,
  dialogStyleProps = {},
  contentStyleProps = {},
  ...props }) => {
  const classes = useStyles()

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  let defaultDialogStyle = {}
  let defaultContentStyle = {}

  if (smDown) {
    defaultContentStyle = {
      root: classes.dialogContentSmall
    }
    defaultDialogStyle = {
      paperFullWidth: classes.dialogWrapperSmall,
      paper: classes.dialogMarginSmall
    }
  }

  return (
    <MuiDialog
      {...props}
      indicatorColor='primary'
      classes={{
        root: classes.dialog,
        ...defaultDialogStyle,
        ...dialogStyleProps,
      }}
      minWidth="sm"
      fullWidth
    >
      {title &&
        <MuiDialogTitle classes={{root: classes.dialogTitle}}>{title}</MuiDialogTitle>
      }
      <MuiDialogContent
        classes={{
          ...defaultContentStyle,
          ...contentStyleProps
        }}
      >
        {children}
      </MuiDialogContent>
      {actionButtons && actionButtons.length > 0 &&
        <MuiDialogActions>
          {actionButtons.map(btn => (
            <Button {...btn} />
          ))
          }
        </MuiDialogActions>
      }
    </MuiDialog>
  )
}

export default Dialog