import clsx from 'clsx'
import {
  Image,
  Button,
  Text,
} from '.'
import {
  useTheme,
  Box,
} from "@material-ui/core"
import { Add, Description } from "@material-ui/icons"
import useStyles from './styles'

export function FileInput({ value, onUploadImage, allowDelete, onDelete }) {
  const classes = useStyles()
  const theme = useTheme()
  const { image_url, file_name, is_uploading, upload_progress } = value || {}
  const imageExt = ['jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff']

  return (
    <div className={classes.imageInput}>
      {image_url &&
        <>
          {imageExt.some(ext => ext.includes(image_url.split('.').pop()))
            ? <div className={classes.imageInputImage}>
              {allowDelete &&
                <div className={classes.imageInputDelete}>
                  <Button kind="delete" variant="icon" size="small" onClick={onDelete} />
                </div>
              }
              <img className={classes.imageFitHeight} src={image_url} />
            </div>
            : <div className={classes.imageInputPlaceholder}>
              <label htmlFor={'perform_upload'}>
                {allowDelete &&
                  <div className={classes.imageInputDelete}>
                    <Button kind="delete" variant="icon" size="small" onClick={onDelete} />
                  </div>
                }
                <Box display='flex' flexDirection='column' alignItems='center'>
                  <Description />
                  <Text variant="body2">
                    {file_name}
                  </Text>
                </Box>
              </label>
            </div>
          }
        </>
      }
      {!image_url &&
        <div className={classes.imageInputPlaceholder}>
          <input
            accept="*"
            style={{ display: 'none' }}
            id="perform_upload"
            type="file"
            onChange={(e) => {
              let maxFileSize = (1024 * 1024) * 16 // 16MB
              if (e?.target?.files[0].size > maxFileSize) {
                alert(`Ukuran file anda (${((e.target.files[0].size) / (1024 * 1024)).toFixed(2)} MB) melebihi batas maksimal 16MB. Coba kembali dengan file berukuran lebih kecil.`)
                e.target.value = ''
                return;
              }

              if (onUploadImage)
                onUploadImage(e.target.files[0])

              // Require to enable input field to accept value again
              e.target.value = ''
            }} />
          <label htmlFor={'perform_upload'}>
            <Box display='flex' flexDirection='column' alignItems='center' style={{ cursor: 'pointer' }}>
              <Add style={{ color: theme.palette.primary.main, marginBottom: theme.spacing(1) }} />
              <Text variant="body2">Upload</Text>
              <Text variant="caption" color='textMuted'>(Maximum 16MB)</Text>
            </Box>
          </label>
        </div>
      }
    </div>
  )
}

export default FileInput