import React, { useState, useContext, useEffect, Suspense, useMemo } from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import qs from 'query-string'
import ls from 'local-storage'
import './App.css'
import { Route, Switch, Link, useLocation, useHistory } from 'react-router-dom'
import {
  AUTH_ACTION_INITIALIZE,
  AUTH_ACTION_LOGIN,
  AUTH_ACTION_LOGOUT,
  CONVERSATION_ACTION_WS_CONNECT,
  CONVERSATION_ACTION_WS_DISCONNECT,
  PROJECT_ACTION_WS_CONNECT,
  PROJECT_ACTION_WS_DISCONNECT,
  AUTH_ACTION_INIT_VISITOR,
  AUTH_ACTION_REGISTER_USER
} from './store/types'
import { eventSource } from './store'
import {
  useAuth,
  useUserInfo,
  useConversationWSStatus,
  useProjectWSStatus,
  useQueueList,
  WS_STATUS,
} from './store/selectors'
import { useDispatch } from 'react-redux'
import {
  Button,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import NavigationController from './navigation'
import { MainViewContactController } from './shared/controllers'
import { SplitView, SplitViewPane } from 'shared/ui'
import { appList } from './apps'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
// snackbar
import { useSnackbar } from 'notistack'
// firebase
import { getMessaging, getToken } from 'firebase/messaging'
import { GlobalDebug } from './remove.consoles'
import { Notification, ProjectNotification } from './navigation/Notification'
import alertmsg from "./assets/sounds/alert-msg.wav";

export const AppContext = React.createContext({
  showModalView: (path, viewName, viewProps) => { },
  showSideBar: (path, viewName, viewProps) => { },
  isSidebarOpen: false,
  activeStore: null,
  userInfo: null,
  conversationWSStatus: null,
})

export const useAppContext = () => useContext(AppContext)

function DynamicApp({ appRoot, loading, ...appProps }) {
  // return <ContactApp />

  // Must use useMemo to prevent component rerendering
  // https://stackoverflow.com/questions/56887650/react-lazy-loaded-component-loosing-its-state-gets-unmounted  
  const App = useMemo(() => React.lazy(() => import(`${appRoot}/App`)), [appRoot])

  // Use to check lazy load
  // const App = useMemo(() => React.lazy(() => {
  //   const x = new Promise((resolve) => {
  //     setTimeout(() => {
  //       return resolve(import(`${appRoot}/App`))
  //     }, 2000)
  //   })
  //   return x;
  // }), [appRoot])

  let loadingDiv = loading || (
    <div style={{ textAlign: "center", marginTop: 20 }}>
      ...
    </div>
  )

  return (
    <Suspense fallback={loadingDiv}>
      <App
        eventSource={eventSource}
        {...appProps}
      />
    </Suspense>
  )
}

function App() {
  const auth = useAuth()
  const userInfo = useUserInfo()
  const conversationWSStatus = useConversationWSStatus()
  const projectWSStatus = useProjectWSStatus()
  const queueList = useQueueList()
  const [modalView, setModalView] = useState(null)
  const [storeModalView, setStoreModalView] = useState(null)
  const [sideBar, setSideBar] = useState(null)
  const [activePane, setActivePane] = useState(0)
  const [activeStore, setActiveStore] = useState(null)
  const [storeItems, setStoreItems] = useState([])
  const [fcMessaging, setFcMessaging] = useState(undefined)
  const [fcmToken, setFcmToken] = useState(null)
  const [lastMsgId, setLastMsgId] = useState([])
  const [projectsCtx, setProjectsCtx] = useState([])

  const theme = useTheme()
  const smDown = useMediaQuery(theme.breakpoints.down("sm"))

  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  // snackbar
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const login = () => dispatch({ type: AUTH_ACTION_LOGIN })
  const logout = () => dispatch({ type: AUTH_ACTION_LOGOUT })

  // condition to check if current route is conversation
  const isConversation = location.pathname.startsWith("/conversations")

  const appContext = {
    showModalView: (path, viewName, viewProps) => setModalView({ path, viewName, viewProps }),
    showStoreModalView: (path, viewName, viewProps) => setStoreModalView({ path, viewName, viewProps }),
    // showSideBar: (path, viewName, viewProps) => setSideBar({ path, viewName, viewProps }),
    showSideBar: (path, viewName, viewParams) => {
      const queryParams = qs.parse(location.search)
      const { auth_success, auth_token, ...rest } = queryParams
      const newQueries = { ...rest, path, viewName, ...viewParams }
      history.replace({ search: qs.stringify(newQueries) })
      setSideBar(true)
    },
    isSidebarOpen: sideBar,
    activeStore,
    setActiveStore,
    storeItems,
    setStoreItems,
    userInfo,
    conversationWSStatus,
    projectWSStatus,
    projectsCtx,
    setProjectsCtx
  }

  useEffect(() => {
    dispatch({ type: AUTH_ACTION_INITIALIZE })

    if (process.env.NODE_ENV === 'production') {
      GlobalDebug(false)
    }

    // get firebase messaging instance
    try {
      const messaging = getMessaging()
      if (messaging)
        setFcMessaging(messaging)
    } catch (err) {
      console.log('firebase cannot be initialized');
    }
  }, [])

  useEffect(() => {

    // init sound variable
    const notifSound = new Audio(alertmsg)

    const onConversationWSMessage = ({ type, data }) => {
      // Forward conversation WS message to our snackbar
      if (type === 'new_message') {
        const isInParties = data?.conversation?.parties.some(item => item.agent?.uid == userInfo?.uid)
        const isCurrentConversation = location.pathname.includes(data?.conversation?.uid)
        const isLastMsgId = lastMsgId.some(item => item == data?.id)
        const isInQueue = queueList?.data?.some(item => item.uid == data?.conversation?.queue?.uid)

        if (
          (document.hidden || !isCurrentConversation) &&
          !isLastMsgId &&
          data?.sender?.agent?.uid !== userInfo?.uid &&
          (isInParties || (data?.conversation?.assigned_at && !data?.conversation?.assigned_to && isInQueue))
        ) {

          let newLastMsgId = lastMsgId.length == 20 ? [...lastMsgId.splice(1, 19), data?.id] : [...lastMsgId, data?.id]
          setLastMsgId(newLastMsgId)

          notifSound.play()
          enqueueSnackbar('New Message', {
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            content: (key, msg) => <Notification {...{ key, msg, data }} />
          })

        }
      }
      else if (type === 'new_assignment') {
        notifSound.play()
        enqueueSnackbar('New Assignment', {
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          content: (key, msg) => <Notification {...{ key, msg, body: "You have been assigned new conversation with customer" }} />
        })
      }
    }

    const onProjectWSMessage = ({ type, data }) => {
      if (type === 'new_assignment') {
        notifSound.play()
        enqueueSnackbar('New Assignment', {
          anchorOrigin: { horizontal: 'right', vertical: 'top' },
          content: (key, msg) => <ProjectNotification {...{ data, key, msg, body: "You have been assigned to a new issue" }} />
        })
      } else if (type === 'new_comment') {
        console.log('userInfo ---> ', userInfo);
        console.log('userInfo uid ---> ', userInfo?.uid);
        console.log('data?.created_by?.uid ---> ', data?.created_by?.uid);
        if (userInfo?.uid !== data?.created_by?.uid) {
          notifSound.play()
          enqueueSnackbar('New Comment', {
            anchorOrigin: { horizontal: 'right', vertical: 'top' },
            content: (key, msg) => <ProjectNotification {...{ data, key, msg }} />
          })
        }
      }
    }

    // listen to new messages & projects
    eventSource.addEventListener("message", onConversationWSMessage)
    eventSource.addEventListener("project", onProjectWSMessage)

    return () => {
      eventSource.removeEventListener("message", onConversationWSMessage)
      eventSource.removeEventListener("project", onProjectWSMessage)
    }
  }, [lastMsgId, isConversation, userInfo, location.pathname, queueList])

  useEffect(() => {
    // getFirebaseToken()
  }, [fcMessaging])

  const getFirebaseToken = () => {
    if (fcMessaging && !fcmToken)
      try {
        getToken(fcMessaging, { vapidKey: 'BAMSIiijs9V_euCneshqzfTIpLOTeKnI2jStz0IDem0-hb2qRYbYRTPuxo79POziYHYwMYf84LRQsBmi6e_o3kA' })
          .then((currentToken) => {
            if (currentToken) {
              setFcmToken(currentToken)
            } else {
              // Show permission request UI
              console.log('No registration token available. Request permission to generate one.');
            }
          })
          .catch((err) => {
            console.log('An error occurred while retrieving token. ', err);
          })
      } catch (error) {
        console.log('firebase instance not defined');
      }
  }

  useEffect(() => {
    const savedFcmToken = ls.get('_fcmToken')

    if (fcmToken) {
      if (!savedFcmToken || savedFcmToken !== fcmToken) {
        ls.set('_fcmToken', fcmToken)

        // re-register after getting token
        if (auth?.isLoggedIn && auth?.goid) {
          const payload = {
            _goid: auth?.goid,
            external_app: 'alpha.apps.directory',
            external_model: 'user',
            external_id: userInfo?.uid,
            first_name: userInfo?.first_name,
            last_name: userInfo?.last_name,
            email: userInfo?.email,
            mobile_no: userInfo?.mobile_no
          }
          dispatch({
            type: AUTH_ACTION_REGISTER_USER,
            payload
          })
        }
      }
    }
  }, [fcmToken])

  const getWSHost = (service) => {
    let host = process.env.REACT_APP_GOAPP_API_URL + "/ws/" + service + "/"
    host = host.replace("http://", "ws://")
    host = host.replace("https://", "wss://")
    host += "?"
    host += "&token=" + auth.token
    return host
  }

  const [isOnline, setIsOnline] = useState(false)

  useEffect(() => {
    window.addEventListener('online', (e) => {
      setIsOnline(true)
    })

    window.addEventListener('offline', (e) => {
      setIsOnline(false)
      dispatch({
        type: CONVERSATION_ACTION_WS_DISCONNECT
      })
    })

    return () => {
    }
  }, [])

  useEffect(() => {
    if (!auth.isLoggedIn)
      return

    if (auth?.isLoggedIn && !auth?.goid) {
      dispatch({ type: AUTH_ACTION_INIT_VISITOR })
    }

    if (auth?.isLoggedIn && auth?.goid) {
      getFirebaseToken()

      const payload = {
        _goid: auth?.goid,
        external_app: 'alpha.apps.directory',
        external_model: 'user',
        external_id: userInfo?.uid,
        first_name: userInfo?.first_name,
        last_name: userInfo?.last_name,
        email: userInfo?.email,
        mobile_no: userInfo?.mobile_no
      }
      dispatch({
        type: AUTH_ACTION_REGISTER_USER,
        payload
      })
    }

    if (conversationWSStatus === WS_STATUS.Idle || conversationWSStatus === WS_STATUS.Disconnected) {
      let timeout = 250
      if (!isOnline && conversationWSStatus === WS_STATUS.Disconnected)
        timeout = 3000

      setTimeout(() => {
        dispatch({
          type: CONVERSATION_ACTION_WS_CONNECT,
          host: getWSHost("conversation"),
        })
        //        alert(getWSHost())
        // props.wsConnect(getWSHost())
      }, timeout)
    }
    else if (conversationWSStatus == WS_STATUS.Connected) {
      // // Refresh conversation list on re-connect.
      // fetchConversationCounts(true)      
    }

    if (projectWSStatus === WS_STATUS.Idle || projectWSStatus === WS_STATUS.Disconnected) {
      let timeout = 250
      if (!isOnline && conversationWSStatus === WS_STATUS.Disconnected)
        timeout = 3000

      setTimeout(() => {
        dispatch({
          type: PROJECT_ACTION_WS_CONNECT,
          host: getWSHost("project"),
        })
        //        alert(getWSHost())
        // props.wsConnect(getWSHost())
      }, timeout)
    }
    else if (projectWSStatus == WS_STATUS.Connected) {
      // // Refresh conversation list on re-connect.
      // fetchConversationCounts(true)      
    }
  }, [auth.isLoggedIn, conversationWSStatus, projectWSStatus, isOnline])

  const renderChildApp = (app) => (props) => {
    // Why div below can't use provided className??
    return (
      <div
        className="page"
        style={{ position: "absolute", width: "100%" }}
      >
        <DynamicApp {...props} appRoot={app.appRoot} />
      </div>
    )
  }

  const renderModalView = (modalView) => {
    const app = appList.find(a => a.path === modalView.path)

    return (
      <DynamicApp
        appRoot={app.appRoot}
        modal={true}
        onClose={() => setModalView(null)}
        {...modalView}
      />
    )
  }

  const renderStoreModalView = (storeModalView) => {
    const app = appList.find(a => a.path === storeModalView.path)

    return (
      <DynamicApp
        appRoot={app.appRoot}
        modal={true}
        onClose={() => setStoreModalView(null)}
        {...storeModalView}
      />
    )
  }

  const renderSideBar = () => {
    const queryParams = qs.parse(location.search, { parseNumbers: true })
    const { auth_success, auth_token, path, viewName, ...viewProps } = queryParams
    const app = appList.find(a => a.path === path)

    const loading = (
      <div className="sidebar-fill">
        <div style={{ textAlign: "center", marginTop: 20 }}>
          ...
        </div>
      </div>
    )

    const closeSidebar = () => {
      setSideBar(null)
      history.replace({ search: qs.stringify({ ...viewProps }) })
    }

    if (smDown) {
      if (app)
        return (
          <DynamicApp
            appRoot={app.appRoot}
            modal={true}
            onClose={closeSidebar}
            loading={loading}
            viewName={viewName}
            viewProps={viewProps}
          />
        )
      else
        return
    }
    else {
      // 20 Dec 2021. If we add SplitViewPane based on side bar existance, it will
      // recreate content pane component every time we show or hide sidebar. Use hidden
      // prop solve this issue
      return (
        <SplitViewPane pane="side" hidden={!app}>
          {app &&
            <div className="sidebar-fill">
              <DynamicApp
                appRoot={app.appRoot}
                sideBar={true}
                onClose={closeSidebar}
                loading={loading}
                viewName={viewName}
                viewProps={viewProps}
              />
            </div>
          }
        </SplitViewPane>
      )
    }
  }

  const redirectToLogin = () => {
    const queryParams = qs.parse(location.search)
    const { auth_success, isloggedout } = queryParams

    if (auth_success !== undefined && auth_success == 0) {
      return <>
        <div style={{ marginBottom: '10px' }}>
          Login Failed.
        </div>
        <Button variant="contained" onClick={login}>Login</Button>
      </>
    } else if (isloggedout) {
      return <>
        <div style={{ marginBottom: '10px' }}>
          You have been logged out.
        </div>
        <Button variant="contained" onClick={login}>Login</Button>
      </>
    } else {
      setTimeout(() => {
        login()
      }, 3000);

      return "You will be redirected to login page, please wait…"
    }
  }

  if (!auth.isInitialized)
    return (
      <div className="App" style={{ textAlign: "center", marginTop: 10 }}>
        ...
      </div>
    )
  else if (!auth.isLoggedIn)
    return (
      <div className="App" style={{ textAlign: "center", marginTop: 10 }}>
        {auth.isAuthenticating ?
          "Authenticating..."
          :
          redirectToLogin()
        }
      </div>
    )

  // Automatically redirect to "/home"
  if (location.pathname === "/")
    history.push("/home")

  const childApp = appList.find(app => location.pathname.startsWith("/" + app.path)) || ""

  return (
    <div className="App">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <AppContext.Provider value={appContext}>
          <NavigationController>
            {smDown ?
              <>
                <Switch location={location}>
                  {appList.map(app =>
                    <Route path={`/${app.path}`} render={renderChildApp(app)} />
                  )}
                </Switch>
                {renderSideBar()}
              </>
              :
              <SplitView activePane={activePane} onSetActivePane={setActivePane} fixedPosition={true}>

                {/* MAIN PANE CONTAINS ROUTE CHILDRES */}
                <SplitViewPane pane="content" fixedPosition={true}>
                  <Switch location={location}>
                    {appList.map(app =>
                      <Route path={`/${app.path}`} render={renderChildApp(app)} />
                    )}
                  </Switch>
                </SplitViewPane>

                {renderSideBar()}

              </SplitView>
            }

            {modalView &&
              renderModalView(modalView)
            }

            {storeModalView &&
              renderStoreModalView(storeModalView)
            }
          </NavigationController>
        </AppContext.Provider>
      </MuiPickersUtilsProvider>
    </div>
  )

  return (
    <div className="App">
      <AppContext.Provider value={appContext}>
        <NavigationController>
          <TransitionGroup>
            <CSSTransition key={childApp.path} timeout={300} classNames="page">
              <Switch location={location}>
                {appList.map(app =>
                  <Route path={`/${app.path}`} render={renderChildApp(app)} />
                )}
              </Switch>
            </CSSTransition>
          </TransitionGroup>

          {modalView &&
            renderModalView(modalView)
          }

        </NavigationController>
      </AppContext.Provider>
    </div>
  )
}

export default App